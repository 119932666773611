import * as yup from 'yup'
import { ObjectId } from 'bson'
import HashIds from 'hashids'
const hashIds = new HashIds(process.env.HASH_SALT)

const idShape = {
  tertiaryId: yup.string().trim().required('tertiaryId is required.'),
  secondaryId: yup.string().trim().required('secondaryId is required.'),
  primaryId: yup.string().trim().required('primaryId is required.'),
}

export const primaryId = yup.object().shape({ primaryId: idShape.primaryId })

const key = yup
  .string()
  .trim()
  .required()
  .test({
    name: 'testKey',
    test(this, value) {
      // decode the value and ensure it's valid
      const id = hashIds.decodeHex(value)
      return ObjectId.isValid(id)
    },
    message: 'key is not valid.',
    exclusive: false,
  })
export const primaryKey = yup.object().shape({
  primaryKey: key,
})
export const secondaryKey = yup.object().shape({
  primaryKey: key,
  secondaryKey: key,
})

export const secondaryKeyId = yup.object().shape({
  primaryKey: key,
  secondaryId: yup.string().required(),
})
export const primaryIdList = yup.object().shape({
  ids: yup
    .array()
    .of(yup.string().trim().required())
    .required('ids are required.'),
})
export const secondaryId = yup.object().shape({
  secondaryId: idShape.secondaryId,
  primaryId: idShape.primaryId,
})
export const secondaryOptionalId = yup.object().shape({
  secondaryId: yup.string().notRequired().nonNullable(),
  primaryId: idShape.primaryId,
})
export const tertiaryId = yup.object().shape({
  tertiaryId: idShape.tertiaryId,
  secondaryId: idShape.secondaryId,
  primaryId: idShape.primaryId,
})

export const tertiaryKey = yup.object().shape({
  primaryKey: key,
  secondaryKey: key,
  tertiaryKey: key,
})

export const ids = yup.object().shape({
  ids: yup.array().of(yup.string().required()).required(),
})

export type idsType = yup.InferType<typeof ids>
export type primaryIdType = yup.InferType<typeof primaryId>
export type primaryIdListType = yup.InferType<typeof primaryIdList>
export type secondaryIdType = yup.InferType<typeof secondaryId>
export type tertiaryIdType = yup.InferType<typeof tertiaryId>
export type primaryKeyType = yup.InferType<typeof primaryKey>
export type secondaryKeyType = yup.InferType<typeof secondaryKey>
export type tertiaryKeyType = yup.InferType<typeof tertiaryKey>
export type secondaryKeyIdType = yup.InferType<typeof secondaryKeyId>
export type secondaryOptionalIdType = yup.InferType<typeof secondaryOptionalId>
