import { ObjectId } from 'bson'
import { Base } from './Base'
import { Commentable } from './Commentable'
import { CustomType } from './CustomType'
import { Document } from './Document'
import { Clip } from './Clip'
import { Note } from './Note'
import { Taggable } from './Taggable'
import { HighlightReel } from './HighlightReel'

export enum InsightType {
  design = 'design',
  feature = 'feature',
  product = 'product',
  usability = 'usability',
  none = 'none',
  custom = 'custom',
}

export interface BaseInsight extends Base, Commentable, Taggable {
  name: string
  description: string
  notes: Note[] | string[] | ObjectId[]
  documents: Document[] | string[] | ObjectId[]
  highlightreels: HighlightReel[] | string[] | ObjectId[]
  clips: Clip[] | string[] | ObjectId[]
  sample_id?: string // eslint-disable-line camelcase
  type: InsightType
  priority: string
  customType: CustomType | ObjectId | string
  insType: string
}
